import React from 'react'
import {graphql, Link, useStaticQuery} from 'gatsby'


const query = graphql`
{
     allStrapiSymptom {
         nodes{
            title
            teaser
            slug
            image {
              localFile {
                url
              }
            }
          }
    }
}

`

// Functional components
const TwoColumns = () => {

    const data = useStaticQuery(query)

    const {allStrapiSymptom: {nodes}} = data

    return (
        <div className="symptoms-area pt-100 pb-70">
            <div className="container">
                <div className="row">
                    {nodes.map((symptom, idx) => {
                        return (
                            <div key={idx} className="col-lg-6 col-md-6">
                                <div className="single-symptom-box">
                                    <Link to={`/symptoms/${symptom.slug}`} className="link-btn">
                                        <div className="image lg">
                                            <img src={symptom.image.localFile.url} alt={symptom.image.alternativeText}/>
                                            <div className="link-btn">
                                                <i className='bx bx-plus'></i>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to={`/symptoms/${symptom.slug}`}>
                                        <div className="content">
                                            <h3>
                                                {symptom.title}
                                            </h3>
                                            <span>{symptom.teaser}</span>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default TwoColumns
